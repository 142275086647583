// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import "bootstrap-icons/font/bootstrap-icons.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { ToastContainer, toast } from "react-toastify";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//       <ToastContainer />
//   </React.StrictMode>
// );

// // git commit -m "update"
// // git push origin main


import React, { useState, useEffect, lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import vid2 from "./Assetss/Loading.mp4";

// Lazy load the App component
const App = lazy(() => import("./App"));

const LoadingScreen = ({ onLoaded }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center vh-100 bg-white"
    >
      <div className="w-75 text-center w-md-50">
        <video
          src={vid2}
          className="w-50 text-center"
          autoPlay
          muted
          playsInline
          preload="auto"
          onEnded={onLoaded}
          style={{
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};

const RootComponent = () => {
  const [loading, setLoading] = useState(true);

  const handleVideoEnd = () => {
    setLoading(false); // Set loading to false when the video ends
  };

  useEffect(() => {
    // Fallback timeout in case the video doesn't trigger `onEnded`
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen onLoaded={handleVideoEnd} />
      ) : (
        <Suspense
          fallback={
            <div className="d-flex align-items-center justify-content-center vh-100">
            </div>
          }
        >
          <App />
          <ToastContainer />
        </Suspense>
      )}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);
